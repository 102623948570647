import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from './global/navbar';
import PageHeader from './global/page-header';
import About from './about-components/block-about';
import Counter from './about-components/counter';
import ValuesAbout from './about-components/values-about';
import BlockAbout from './about-components/block-about-two';
import Footer from './global/footer';


const AboutPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="About Us" Subheader="About" bgimg={`${rpdata?.gallery?.[6]}`} />
        <About />
        <Counter />
        <ValuesAbout />
        <BlockAbout />
        <Footer />
    </div>
}

export default AboutPage

