import React from "react";

import WhatsappChat from "../global/botonWhatsapp/BotonWhatsapp"

//

const LiveChat = (props) => {


  return (
    <div>
      <WhatsappChat />
      <div className="visor_Counter">
        <div class="elfsight-app-c0474907-4411-47e7-bb4f-4e40b4e9dfc3"></div>
      </div>
    </div>
  );
};

export default LiveChat;
