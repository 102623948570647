import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
// import { ButtonContent } from './boton/ButtonContent'

const VideoPromocional = ({ image, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : rpdata?.gallery?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    <h3 className='font-1'>{rpdata?.dbSlogan?.[2]?.slogan}</h3>
                    <p>{text}</p>
                    {/* <ButtonContent /> */}
                </div>

                        <iframe
                            title="Promotional video"
                            className='vid'
                            src={rpdata?.videosPromo?.[0].link}
                            width="640" height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        />
            </div>
        </div>
    )
}

export default VideoPromocional


