import React from 'react';
import Navbar from './global/navbar';
import Banner from './home-components/banner';
import Values from './about-components/values';
import HomeBlockOne from './home-components/home-block';
import RecentProjects from './home-components/recent-projects-home';
import CallToAction from './home-components/call-to-action';
import Directories from './home-components/directories';
import HomeServices from './home-components/home-services';
import Footer from './global/footer';
import ModalHome from "./home-components/ModalComponent"
import VideoPromocional from './global/VideoPromocional';
import HomeAbout from './about-components/block-about';
import Map from './contact-components/map';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Values />
        <HomeBlockOne />
        <CallToAction />
        <HomeAbout/>
        <VideoPromocional />
        <Directories />
        <RecentProjects />
        <HomeServices />
        <ModalHome />
        <Map />
        <Footer />
    </div>
}

export default Home_V1

